/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/* eslint-disable react/jsx-curly-spacing */

import React from 'react';
import { bool, object, string } from 'prop-types';
// TO BE DELETED WHEN EDS BUTTON GETS APPROVED
import { useSelector } from 'react-redux';
// ^^ TO BE DELETED WHEN EDS BUTTON GETS APPROVED ^^
import mbpLogger from 'mbp-logger';
import useStyles from 'isomorphic-style-loader/useStyles';
import { getFeatureFlag } from '../../../../../../state/ducks/App/ducks/Config/Config-Selectors';

import CTA from '../CTA/CTA';
import messageCss from './Message.module.css';
import getBackgroundColor from '../../../../../helpers/getBackgroundColor';
import replaceSpecialChar from '../../../../../helpers/replaceSpecialChar';
import StyledButton from '../../../../EnterpriseDesignSystem/Button/StyledButton';
import normalizeAltCopy from '../../../../../helpers/normalizeAltCopy';

// helpers
import cmsCssClassesToString from '../../../../EnterpriseDesignSystem/helpers/cmsCssClassesToString';

const parsePosition = (position) => {
    useStyles(messageCss);
    const positionStyles = {};
    const styleArr = position?.split(';') || [];
    styleArr?.forEach((style) => {
        const [propName, propValue] = style?.split(':') || [];
        if (propName && propValue) {
            positionStyles[propName.trim()] = propValue.trim().replace(/'/g, '');
        }
    });
    return positionStyles;
};

const renderButton = (ctaArray, buttonContainer) => (
    <div className={buttonContainer}>
        {ctaArray?.map((d) => (
            <StyledButton
                label={d.link.title}
                link={d.link.href}
                variant={d.variant}
                size={d.size}
                attributes={d?.html_attributes}
            />
        ),
        )}
    </div>
);

const MessageStandard = ({
    data, fullBackground, size,
}) => {
    if (!Object.keys(data)?.length) {
        return mbpLogger.logError({
            appName: process.env.npm_package_name,
            component: 'UniversalTemplate/SubComponents/Message/MessageStandard.js',
            message: 'No data available to render component',
        });
    }
    /* TO BE DELETED WHEN EDS BUTTON GETS APPROVED */
    const ffIsEdsButtonEnabled = useSelector(getFeatureFlag('is-eds-button-enabled'));
    /* TO BE DELETED WHEN EDS BUTTON GETS APPROVED */
    let messagePosition = {};
    switch (size) {
        case 'tall':
            messagePosition = data?.message_position_tall;
            break;
        case 'medium':
            messagePosition = data?.message_position_medium;
            break;
        default: messagePosition = data?.message_position;
    }
    const {
        has_background = false,
        message_background_color = {},
        content = {
            heading: { css_classes: ['header2', 'u-margin-bottom-10'], text: '' },
            sub_heading: { css_classes: ['text1', 'u-margin-bottom-20'], text: '' },
        },
        heading = '', sub_heading = '',
        message_border_color = '',
        message_has_border = false,
        color = '',
        heading_font = '',
        heading_font_size = '',
        sub_heading_font = '',
        sub_heading_font_size = '',
        image = '',
        max_image_width = '',
    } = data;
    if (!image?.url && !heading && !sub_heading &&  data?.cta?.length === 0) {
        return null;
    }
    const position = parsePosition(messagePosition?.message_position);
    const headingText = content?.heading?.text;
    const headingCssClasses = cmsCssClassesToString(content?.heading?.css_classes);
    const subHeaderText = content?.sub_heading?.text;
    const subHeaderCssClasses = cmsCssClassesToString(content?.sub_heading?.css_classes);
    const messageBackground = fullBackground && data?.has_background;
    const msgBorderColor = message_has_border ? `1px solid ${getBackgroundColor(message_border_color)}` : '';

    // start bloat
    // These four should be stripped when we get a proper font selector or RTE in here
    const standardHeadingFontSet = '"PlayfairDisplayBold", "Crimson", "ArcherBold", Garamond, Times, serif';
    const standardSubHeadingFontSet = '"LatoRegular", "Lato", "NeutraBold", Verdana, Geneva, Tahoma, sans-serif;';
    const cleanFont = (font) => {
        const regex = /font-family:(.+);/gm;
        return regex.exec(`${font}`)?.[1];
    };
    // eslint-disable-next-line no-nested-ternary
    const whichFont = (font, type) => (font.length ? cleanFont(font) : (type === 'heading' ? standardHeadingFontSet : standardSubHeadingFontSet));
    const headingStyleGenerator = (headingClass, headingFont, headingFontSize, type) => {
        const style = {};
        style.fontFamily =  whichFont(headingFont, type);
        style[headingClass] = color;
        if (headingFontSize) style.fontSize = headingFontSize;
        return { style };
    };
    // end bloat

    let containerClass = '';
    switch (messagePosition?.message_position) {
        case 'align-items: center; justify-content: center;':
        case 'align-items: center; justify-content:  flex-end;':
        case 'align-items: center; justify-content: flex-start;':
            containerClass = `${messageCss.container} ${messageCss.messageTextCenter}`;
            break;
        case 'align-items: flex-end; justify-content: center;':
        case 'align-items: flex-end; justify-content: flex-end;':
        case 'align-items: flex-end; justify-content: flex-start;':
            containerClass = `${messageCss.container} ${messageCss.messageTextRight}`;
            break;
        default: containerClass = messageCss.container;
    }
    return  (
        <div className={`${containerClass} ${messageBackground ? messageCss.backgroundContainer : ''}`} style={{ ...position }}>
            <div className={`${messageCss.messageWrapper} ${has_background ? messageCss.messageBgColor : ''}`} style={{ '--msg-bg-color': getBackgroundColor(message_background_color, true), border: msgBorderColor }}>
                {image?.url && <img src={image.url} alt={normalizeAltCopy(image.title)} style={{ marginBottom: '1em', maxWidth: (max_image_width ? `${max_image_width}px` : '80%') }} />}
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                {headingText ? <h3 class={headingCssClasses}>{replaceSpecialChar(headingText, true)}</h3> : (heading && <h3 className={messageCss.heading} {...headingStyleGenerator('--message-heading-color', heading_font, heading_font_size, 'heading')}>{replaceSpecialChar(heading, true)}</h3>)}
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                {subHeaderText ? <p className={subHeaderCssClasses}> {replaceSpecialChar(subHeaderText, true)}</p> : sub_heading && <div className={messageCss.subHeading} {...headingStyleGenerator('--message-subheading-color', sub_heading_font, sub_heading_font_size, 'subheading')}> {replaceSpecialChar(sub_heading, true)}</div>}
                { /* TO BE DELETED WHEN EDS BUTTON GETS APPROVED */ }
                {ffIsEdsButtonEnabled && (data?.styled_button?.length > 0)
                    ? renderButton(data?.styled_button, messageCss.buttonContainer)
                    : (data?.cta && Object.keys(data.cta)?.length > 0) && <CTA data={data.cta} />}
                { /* TO BE DELETED WHEN EDS BUTTON GETS APPROVED */ }
            </div>
        </div>
    );
};

MessageStandard.propTypes = {
    data: object.isRequired,
    fullBackground: bool,
    size: string,
};
MessageStandard.defaultProps = {
    fullBackground: false,
    size: 'banner',
};

export default MessageStandard;
